import http from "@/utils/request.js";

export function consumerEducationList(data) {
  return http({
    url: "/azCustomer/consumerEducationList",
    method: "get",
    params: data,
  });
}
export function listDetails(data) {
  return http({
    url: "/azCustomer/getConsumerEducation",
    method: "post",
    data,
  });
}

export function phFinancialPromotion(data) {
  return http({
    url: "/azCustomer/phFinancialPromotion",
    method: "get",
    params: data,
  });
}
export function getPhFinancialPromotion(data) {
  return http({
    url: "/azCustomer/getPhFinancialPromotion",
    method: "post",
    data,
  });
}
// 
